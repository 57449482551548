export function builderioCardClickEvent(cardData) {
  snowplow('trackStructEvent', 'ux', 'interaction', 'builder-card-click', cardData.linkUrl, null,
    [
      {
        schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
        data: {
          name: 'builder-card-click',
          object_type: cardData.cardType || null,
          object_position: cardData.position || null,
          component_name: cardData.carouselName || null,
          component_position: null,
        }
      },
    ])
}

export function builderioCardOutboundEvent(cardData) {
  snowplow('trackStructEvent', 'ux', 'interaction', 'builder-card-click', cardData.linkUrl, null,
    [
      {
        schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
        data: {
          name: 'builder-outbound-click',
          object_type: cardData.cardType || null,
          object_position: cardData.position || null,
          component_name: cardData.carouselName || null,
          component_position: null,
        }
      },
    ])
}