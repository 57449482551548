export const isIOS = () => {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true
  } else {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
  }
}

export const isPad = () => {
  return /iPad/.test(navigator.platform) || navigator.platform === 'MacIntel';
}
export const isSafari = (() => {
  const ua = navigator.userAgent.toLowerCase()
  return isIOS() && /safari/.test(ua) && !/(chrome|crios|fxios)/.test(ua)
})()

export const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase());

export const isDesktopChrome = (() => {
  const isChromium = window.chrome
  const winNav = window.navigator
  const vendorName = winNav.vendor
  const isOpera = typeof window.opr !== 'undefined'
  const isIEedge = winNav.userAgent.indexOf('Edg') > -1
  const isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return true
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    // is Google Chrome
    return true
  } else {
    // not Google Chrome
    return false
  }
})()
 
export const isAndroid = () => navigator.userAgent.toLowerCase().includes('android')