import { useEffect, useState } from 'react';
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import '@builder.io/widgets';

import InvalidRoute from '../../../../../components/utils/InvalidRoute';
import { gon } from '../../../../../../constant';
import './RegisterComponents';
// import Loader from '../../../../../components/Loader';
// import { getIsMobile } from '../../../reducers';
// import { useSelector } from 'react-redux';

import { isIOS, isAndroid } from '../../../utilis/detectBrowser';

builder.init(gon.builder_io.key);

const removeTrailingSlash = (url) => url.replace(/\/$/, '');

const definePlatformHelper = () => {
  window.platformHelper = {
    isNativeApp: !!window.ReactNativeWebView,
    isNativeAndroid: window.localStorage?.getItem('device_os') === 'android',
    isNativeIos: window.localStorage?.getItem('device_os') === 'ios',
    isNativeIos: window.localStorage?.getItem('device_os') === 'ios',
    extEnable: window.localStorage?.getItem('ext_enable') === 'true',
    extPermission: window.localStorage?.getItem('ext_permission') === 'true',
    extPermissionPartial: window.localStorage?.getItem('ext_permission_Partial') === 'true',
    isIos: isIOS() && !window.ReactNativeWebView,
    isAndroid: isAndroid() && !window.ReactNativeWebView,
    isEditing: builder.isEditing, 
    isPreviewing: builder.isPreviewing,
    lbNativeAppVersion: parseFloat(window.localStorage.getItem('lb_native_app_version') || 0)
  }
}

export default function BuilderIOConfig() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const isMobile = useSelector(getIsMobile);

  // Get the page content from Builder

  useEffect(() => {
    definePlatformHelper();
  }, []);

  useEffect(() => {
    const mainPreLoader = document.getElementById('mainPreLoader');
    if (mainPreLoader) {
      mainPreLoader.style.display = 'block';
    }

    const fetchContent = async () => {
      const pathname = removeTrailingSlash(window.location.pathname);
      try {
        const fetchedContent = await builder.get('page', { url: pathname }).promise();
        setContent(fetchedContent);
        setNotFound(!fetchedContent);
        // if (!fetchedContent) {
        //   setLoading(false);
        // }
        if (mainPreLoader) {
          if (window.ReactNativeWebView) {
            setTimeout(() => {
              mainPreLoader.style.display = 'none';
            }, 1000);
          } else {
            mainPreLoader.style.display = 'none';
          }
        }

      } catch (error) {
        console.error('Error fetching content:', error);
        setNotFound(true);
        // setLoading(false);
        if (mainPreLoader) {
          mainPreLoader.style.display = 'none';
        }
      }
    };

    setContent(null); // Reset content before fetching
    fetchContent();
  }, [window.location.pathname]);

  // if no page is found, return a page not found
  if (notFound && !isPreviewingInBuilder) {
    return <InvalidRoute />;
  }

  // const onContentLoaded = () => {
  //   setLoading(false);
  //   const mainPreLoader = document.getElementById('mainPreLoader');
  //   if (mainPreLoader) {
  //     mainPreLoader.style.display = 'none';
  //   }
  // }

  // return the page when found
  return (
    <div className='container-fluid full-width-container builder-container'>
      {/* {loading && (
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '100vh' }}>
          <Loader isLoading size={isMobile ? 60 : 70} />
        </div>
      )} */}

      {/* onContentLoad={onContentLoaded} */}
      {content && <BuilderComponent model='page' content={content} />}
    </div>
  );
}
