import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import {
  // extEnable,
  // extArrowDown,
  // permissionReviewBE,
  // lbLogoSmall,
  beIosSupport,
  copyIcon,
  iconCheckFilled,
  // chevronLeft,
  extensionIpad,
  playCircleWhite,
  extEnableIpad,
  extArrowUp,
  extPermissionsIPad
} from '../../../../components/Icons'
import {isSafari, isIOS, isPad} from '../../utilis/detectBrowser'
import { LB_SAFARI_BROWSER_EXTENSION_ID } from '../../../../../constant'
import IphoneExtActivationVideo from '../HomePageExtCrossSell/IphoneExtActivationVideo'
import EnableExtension from "./EnableExtension";
import Permission from "./Permission";
import PermissionWithBar from "./PermissionWithBar";

const ActivateExtension = () => {
  const [copied, setCopied] = useState(false)
  const history = useHistory()
  const isIOSDevice = isIOS()
  const currentPathName = useLocation().pathname
  const params = new URLSearchParams(useLocation().search)
  const lbnid = params.get('lbnid')
  const [showVideo, setShowVideo] = useState(false)
  const [permissionBarVisibile, setPermissionBarVisibile] = useState(false)

  if (lbnid) {
    window.localStorage.setItem('lbnid', lbnid)
  }
  if (params) {
    params.delete('lbnid')
    params.size > 0 && window.localStorage.setItem('utmParams', params.toString())
  }

  useEffect(() => {
    if (LB_SAFARI_BROWSER_EXTENSION_ID && isIOSDevice && isSafari) {
      if (typeof window.chrome === 'undefined' && typeof window.browser !== 'undefined') {
        window.chrome = window.browser
      }

      // try {
      //   // console.log('LB_SAFARI_BROWSER_EXTENSION_ID', LB_SAFARI_BROWSER_EXTENSION_ID)
      //   // console.log('Send message to safari extension')
      //   // console.log('window.chrome', window.chrome?.runtime?.sendMessage)
      //   window.chrome?.runtime?.sendMessage(LB_SAFARI_BROWSER_EXTENSION_ID, { type: 'check-installed' }, function (response) {
      //     console.log('response', response)
      //     if (!response) {
      //       return; // Handle no response case
      //     }
      //     console.log('response', response)
      //     if (response?.lbExtInstalled === 'installed') {
      //       history.push('/extension-active')
      //     } else if (response?.partialPermission === 'partialPermission') {
      //       history.push('/mobile/ext/permissions-details') 
      //     }
      //   })
      // } catch (e) {
      //   console.log('error', e)
      //   Sentry.captureException(e)
      // }

      const onMessage = (message) => {
        if (message?.data?.lbExtInstalled === 'installed') {
          history.push('/extension-active')
        }else if (message?.data?.partialPermission === 'partialPermission') {
          history.push('/mobile/ext/partial-permission')
        }
      }

      window.addEventListener('message', onMessage)

      return () => {
        window.removeEventListener('message', onMessage)
      }
    }

    if (!isIOSDevice) {
      history.push('/')
    }

    document.body?.classList?.add('bg-white')
    return () => {
      document.body?.classList?.remove('bg-white')
    }
  }, [])

  useEffect(() => {
    if(currentPathName === '/mobile/ext/partial-permission'){
      let innerHeight = localStorage.getItem('web_inner_height')
      if (parseInt(innerHeight) !== window.innerHeight && barHeight(innerHeight)){
        setPermissionBarVisibile(true)
        window.localStorage.setItem('enable_bar_visible', 'true')
        //Top bar showing for 1 min
        setTimeout(() => {
          window.localStorage.removeItem('enable_bar_visible')
        },60000)
      }else{
        setPermissionBarVisibile(false)
      }
      window.addEventListener('resize', handleResize);
    }else{
      window.localStorage.removeItem('enable_bar_visible')
    }

    if (currentPathName === '/mobile/ext/enable') {
      window.localStorage.setItem('web_inner_height', window.innerHeight.toString())
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  },[currentPathName])

  const barHeight = (innerHeight) => {
    //Single line height or double line height
    return (
        parseInt(innerHeight) - window.innerHeight === 46 ||
        parseInt(innerHeight) - window.innerHeight === 62
    );
  }

  const handleResize = () => {
    let innerHeight = localStorage.getItem('web_inner_height')
    let flagEnableStatus = localStorage.getItem('enable_bar_visible')
    if (parseInt(innerHeight) !== window.innerHeight && barHeight(innerHeight) && flagEnableStatus){
      setPermissionBarVisibile(true)
    }else{
      if (!flagEnableStatus) {
        setPermissionBarVisibile(false)
      }
    }
  }

  const copyUrl = () => {
    if (copied) return
    setCopied(true)
    navigator.clipboard.writeText(window.location.href)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  if (!isIOSDevice) return null

  const handleVideoClose = () => setShowVideo(false)
  const handleVideoShow = () => setShowVideo(true)

  // const actionButton = <button onClick={handleVideoShow} className='action-help'> <img src={playCircleWhite} className='play-icon' /> Help? </button>

  if (!isSafari) {
    return (
      <div className='be-view'>
        <div className='d-flex flex-column'>
          <span className='title second'>
            The Little Birdie Mobile Extension is only available on Safari
          </span>
          <span className='sub-title dark'>Copy the url for this page and open in Safari to begin.</span>
        </div>
        <img
          className='active-be-img'
          src={beIosSupport}
        />
        <div onClick={copyUrl} className='icon-bt'>
          <img src={copied ? iconCheckFilled : copyIcon} />
          <span> {copied ? 'Copied to clipboard' : 'Copy url'} </span>
        </div>
      </div>
    )
  }

  if (isPad() && currentPathName === '/mobile/ext/enable') {
    return (
        <div className='be-view enable'>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', width: '100%'}}></div>
            <img src={extArrowUp} className={'down'} style={{marginRight: 195}} height={82}/>
          </div>
           <span className='title'>
             <span className='sub-text'>Step 1</span> - Turn on the Little Birdie extension for Safari
           </span>
           <span className='sub-title' style={{marginTop:-45}}>We do not sell your personal information. Ever. It’s all in our <b onClick={() => history.push('/privacy')}><u>privacy policy.</u></b></span>
          <img
              className='active-be-img'
              src={extEnableIpad}
          />

          <ul className='steps-view' style={{marginTop: 50}}>
            <li className='step-title'>
              <div className='d-flex'>
                <span>Tap on extension      <img
                    src={extensionIpad}
                    height={15}
                /> in the search bar</span>
              </div>
            </li>
            <li className='step-title'>
              <div className='d-flex'>
                <span>Then Tap <b>Manage Extensions</b></span>
              </div>
            </li>
            <li className='step-title'>
              <div className='d-flex'>
                <span>Turn on Little Birdie</span>
              </div>
            </li>
          </ul>
        </div>
    )
  }

  if (currentPathName === '/mobile/ext/enable') {
    return <EnableExtension/>
  }

  if (isPad() && (currentPathName === '/mobile/ext/permissions-details' || currentPathName === '/mobile/ext/partial-permission')) {
    return (
      <div className='be-view'>
        <IphoneExtActivationVideo show={showVideo} handleClose={handleVideoClose} />
        <div className='d-flex flex-column'>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', width: '100%'}}></div>
            <img src={extArrowUp} className={'down'} style={{marginRight: 195}} height={82}/>
          </div>
          <span className='title second'>
            <span className='sub-text'>Step 2</span> - Great work! Next grant us permissions so we can show you the best prices
          </span>
          <span className='sub-title'>We do not sell your personal information. Ever. It’s all in our <b onClick={() => history.push('/privacy')}><u>privacy policy.</u></b></span>
        </div>
        <div style={{marginTop:20}}>
        <img
          className='active-be-img'
          src={extPermissionsIPad}
        />
        </div>
        <ul className='steps-view' style={{marginTop:50}}>
          <li className='step-title'>
            <div className='d-flex'>
                <span>Tap on extension
                  <img
                    src={extensionIpad}
                    height={15}
                /> in the search bar</span>
            </div>
          </li>
          <li className='step-title'>
            <div className='d-flex'>
              {/* <span className='index'>2. </span> */}
              <span>Tap <b>Little Birdie</b></span>
            </div>
          </li>
          <li className='step-title'>
            <div className='d-flex'>
              <span>Select <b>Always Allow</b></span>
            </div>
          </li>
          <li className='step-title'>
            <div className='d-flex'>
              <span>Select <b>Always Allow on every website</b></span>
            </div>
          </li>
        </ul>
      </div>
    )
  }

  if (currentPathName === '/mobile/ext/permissions-details') {
    return <Permission/>
  }

  if (currentPathName === '/mobile/ext/partial-permission') {
    if (permissionBarVisibile) {
      return <PermissionWithBar/>
    } else {
      return <Permission/>
    }
  }
}

export default ActivateExtension
